
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  MultiCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    MultiCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 6;
    const router = useRouter();

    const title =
      "Do you have or have you had any of the following conditions?";
    const options = [
      {
        label: "Asthma",
        value: "Asthma",
      },
      {
        label: "Arthritis",
        value: "Arthritis",
      },
      {
        label: "Neurological Disorder or Chronic Headaches",
        value: "Neurological Disorder or Chronic Headaches",
      },
      {
        label: "Blood Transfusion",
        value: "Blood Transfusion",
      },
      {
        label: "Psychiatric Illness",
        value: "Psychiatric Illness",
      },
      {
        label: "Bowel or Stomach Issues",
        value: "Bowel or Stomach Issues",
      },
      {
        label: "Cholesterol Disorder",
        value: "Cholesterol Disorder",
      },
      {
        label: "Seizures or Epilepsy",
        value: "Seizures or Epilepsy",
      },
      {
        label: "Thyroid Disorder",
        value: "Thyroid Disorder",
      },
      {
        label: "Eye Disorder",
        value: "Eye Disorder",
      },
      {
        label: "Urinary or Kidney Disorder",
        value: "Urinary or Kidney Disorder",
      },
      {
        label: "Cancer",
        value: "Cancer",
      },
      {
        label: "Diabetes",
        value: "Diabetes",
      },
      {
        label: "Stroke",
        value: "Stroke",
      },
      {
        label: "Heart disease or heart attack",
        value: "Heart disease or heart attack",
      },
      {
        label: "Liver problems",
        value: "Liver problems",
      },
      {
        label: "Gallbladder issues",
        value: "Gallbladder issues",
      },
      {
        label:
          "Blood Clotting Disorder, blood clot in legs or blood clot in lungs",
        value:
          "Blood Clotting Disorder, blood clot in legs or blood clot in lungs",
      },
      {
        label:
          "Systemic Lupus Erythematous WITH positive antiphospholipid antibodies (lupus anticoagulant, anticardiolipin antibody, anti-β2-glycoprotein)",
        value:
          "Systemic Lupus Erythematous WITH positive antiphospholipid antibodies (lupus anticoagulant, anticardiolipin antibody, anti-β2-glycoprotein)",
      },
      {
        label: "I don't have any of these conditions",
        value: "NIL",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      title,
      questionNumber,
    };
  },
});
